import Layout from '@/layouts/main'

const serviceRouter = {
  path: '/service',
  component: Layout,
  children: [
    {
      name: 'Service',
      path: '',
      component: () => import('@/views/service'),
      meta: {
        needAuth: true,
        rootMenu: 'Service'
      }
    }
  ]
}

export default serviceRouter
