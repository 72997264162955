import Layout from '@/layouts/main'

const faqRouter = {
  path: '/faq',
  component: Layout,
  children: [
    {
      name: 'FaqList',
      path: '',
      component: () => import('@/views/faq'),
      meta: {
        needAuth: true,
        rootMenu: 'Faq',
        breadcrumb: [
          {
            text: 'Technical Support'
          }
        ]
      }
    },
    {
      name: 'FaqAction',
      path: ':action',
      component: () => import('@/views/faq/action'),
      props: route => ({ action: route.params.action }),
      meta: {
        needAuth: true,
        rootMenu: 'Faq',
        breadcrumb: [
          {
            text: 'New Article'
          }
        ]
      }
    }
  ]
}

export default faqRouter
