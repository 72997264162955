import Layout from '@/layouts/main'

const directoryRouter = {
  path: '/directory',
  component: Layout,
  children: [
    {
      name: 'Directory',
      path: '',
      component: () => import('@/views/directory'),
      meta: {
        needAuth: true,
        rootMenu: 'Directory'
      }
    }
  ]
}

export default directoryRouter
