import Layout from '@/layouts/main'

const requirementRouter = {
  path: '/requirement',
  component: Layout,
  children: [
    {
      name: 'Requirement',
      path: '',
      component: () => import('@/views/requirement'),
      meta: {
        needAuth: true,
        rootMenu: 'Requirement',
        breadcrumb: [
          {
            text: 'Requirement'
          }
        ]
      }
    }
  ]
}

export default requirementRouter
