import Layout from '@/layouts/main'

const anaylsisRouter = {
  path: '/anaylsis',
  component: Layout,
  children: [
    {
      name: 'Anaylsis',
      path: '',
      component: () => import('@/views/anaylsis'),
      meta: {
        needAuth: true,
        rootMenu: 'Anaylsis'
      }
    }
  ]
}

export default anaylsisRouter
