<template>
  <div class="layout">
    <the-header />

    <div class="d-flex align-items-start">
      <the-menu />

      <main class="flex-grow-1 ml-8">
        <router-view />
      </main>
    </div>
  </div>
</template>

<script>
import TheMenu from '@/components/layouts/partner/menu'
import TheHeader from '@/components/layouts/partner/header'

export default {
  name: 'Layout',
  components: {
    TheMenu,
    TheHeader
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~@/assets/style/variables';

.layout {
  height: 100vh;
  min-width: 1440px;
  padding: 2rem 3.75rem;
}
</style>
