import Layout from '@/layouts/main'

const informationRouter = {
  path: '/information',
  component: Layout,
  children: [
    {
      name: 'Information',
      path: '',
      component: () => import('@/views/information'),
      meta: {
        needAuth: true,
        rootMenu: 'Information',
        breadcrumb: [
          {
            text: 'Information'
          }
        ]
      }
    }
  ]
}

export default informationRouter
