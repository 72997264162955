import Layout from '@/layouts/main'

const clinicRouter = {
  path: '/clinic',
  component: Layout,
  children: [
    {
      name: 'Clinic',
      path: '',
      component: () => import('@/views/clinic'),
      meta: {
        needAuth: true,
        rootMenu: 'Clinic',
        breadcrumb: [
          {
            text: 'Clinic List'
          }
        ]
      }
    }
  ]
}

export default clinicRouter
